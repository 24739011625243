<template>
  <!-- 积分弹窗 -->
  <div class="IntegralDialog">
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-if="memberDialogVisible"
      title="提示"
      :visible.sync="memberDialogVisible"
      :before-close="handleClose"
      width="880px"
    >
      <div slot="title" class="dialog_title">
        <div class="title">
          <span>{{GET_USER_INFO.vipIs?'续费会员':'开通会员'}}</span>
        </div>
        <div class="title_content">畅享政策、财税两大模型及其他领域大模型</div>
        <div class="vip">
          <img src="../../assets/images/vip/VIP.png" alt="" />
        </div>
      </div>
      <div class="main">
        <!-- 价格 -->
         <div class="price" :class="{'price_chatBot': $route.name == 'chatBot'}">
          <div
            v-for="(item, index) in priceList"
            :key="index"
            :class="{ price_item: true, price_active: priceInd === index }"
            @click="changePriceFn(item, index)"
          >
            <template>
              <div class="integral">{{ item.productName }}</div>

              <div
                v-if="priceInd == index"
                class="money"
                style="
                  color: red;
                  display: flex;
                  align-items: baseline;
                  justify-content: center;
                "
              >
                ¥{{ paidAmount }}
                <span class="recommend_price">
                  {{ item.price }}
                  <a class="public_beta_period" v-if="item.productName == '1个月VIP'">（公测期）</a>
                </span>
              </div>
              <div v-else class="money">
                ¥{{ item.price }}
                <a class="public_beta_period_two" v-if="item.productName == '1个月VIP'">（公测期）</a>
              </div>

              <div class="preferential preferential_through">原价：{{item.originalPrice}}</div>
              <!-- <div
                v-if="paidAmountDay && priceInd === index"
                class="preferential"
              >
                每天仅需{{ paidAmountDay }}元
              </div>
              <div v-else class="preferential">
                每天仅需{{ item.amountDay }}元
              </div> -->
            </template>

            <div class="limited" v-if="item.adviseSign === 'Y'">
              <img src="../../assets/images/vip/recommend.png" alt="" />
            </div>
          </div>
          <template v-if="$route.name == 'chatBot'">
            <div class="price_line"></div>
            <router-link to="invite">
              <div
                class="price_activity"
                :class="{ price_item: true, price_active: priceInd === 3 }"
              >
                <template>
                  <div class="integral">邀请好友注册免费领提问权益</div>
                </template>

                <div class="recommend">
                  <img src="../../assets/images/vip/activity.png" alt="" />
                </div>
              </div>
            </router-link>
          </template>
        </div>
        <!-- 价格 -->

        <template v-if="IsShowQrcode">
          <div class="qrcode">
            <div class="left">
              <div class="account">购买账户：{{ orderInfo.userName }}</div>
              <div class="account">购买产品：{{ orderInfo.productName }}</div>
              <div class="account">
                到期时间：
                {{ dayjs(orderInfo.expireDate).format('YYYY-MM-DD') }}
              </div>
              <div class="account" v-if="orderInfo.equityDeduction">
                优惠券抵扣金额：
                {{ orderInfo.equityDeduction ? orderInfo.equityDeduction : '' }}
              </div>
              <div class="account">
                实付金额：<span
                  style="font-weight: 500; color: #f71414; font-size: 18px"
                  >￥{{ orderInfo.paidAmount }}</span
                >
              </div>

              <div
                :class="{ alipay: true, active_pay: payment === 0 }"
                @click="changepaymentFn(0)"
              >
                <img src="../../assets/images/vip/alipay.png" alt="" />
                支付宝付款
                <div class="choose_pay" v-if="payment === 0">
                  <img src="../../assets/images/vip/right.png" alt="" />
                </div>
              </div>

              <div class="agreement">开通前请阅读
                <router-link to='membershipAgreement'>《会员服务协议》</router-link>
              </div>
            </div>
            <div class="right">
              <!-- 二维码 -->
              <template v-if="!paySuccess">
                <div class="code">
                  <iframe
                    id="zfbCode"
                    :srcdoc="qianHtml"
                    frameborder="no"
                    border="0"
                    marginwidth="0"
                    marginheight="0"
                    scrolling="no"
                  >
                  </iframe>
                </div>
                <div class="scan">
                  <div>
                    <img src="../../assets/images/vip/scan.png" alt="" />
                  </div>
                  <div>
                    打开手机支付宝<br />
                    扫码继续付款
                  </div>
                </div>
              </template>
              <!-- 二维码 -->

              <!-- 支付成功 -->
              <template v-else>
                <div class="success_pay">
                  <img src="../../assets/images/vip/success_pay.png" alt="" />
                  <div class="success_text">支付完成</div>
                  <div class="success_pay_btn" @click="successFn">确认</div>
                  <div>您可在个人中心查看订单详情</div>
                </div>
              </template>
              <!-- 支付成功 -->
            </div>
          </div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProductInfoListAPI,
  getDmxVipInfoAPI,
  orderCreateAPI,
  orderQueryAPI,
  remainingDaysAPI
} from '@/api'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'memberDialog',
  components: {},
  props: {},
  data() {
    return {
      memberType: '',
      memberDialogVisible: false,
      priceInd: 0,
      IsShowQrcode: true,
      payment: 0,
      paySuccess: false,
      activePrictItem: {}, // 当前选择的套餐
      qianHtml: '', // 二维码
      orderNo: '', // 订单号
      priceList: [],
      orderInfo: {},
      memberTimer: null,
      paidAmount: null, // 实付
      paidAmountDay: null // 每日实付
    }
  },
  computed: {
    ...mapGetters(['GET_USER_INFO'])
  },
  watch: {
    memberDialogVisible(newValue) {
      if (!newValue) {
        clearInterval(this.memberTimer)
      }
      if (newValue) {
        this.priceInd = 0
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapMutations(['SET_USER_INFO']), // 设置用户信息
    async handleClose(done) {
      // 支付成功后显示用户信息
      this.IsShowQrcode = true
      if (this.paySuccess) {
        this.RefreshParentMember()
      }
      clearInterval(this.memberTimer)
      this.memberTimer = null
      done()
    },
    memberDialog() {
      // 弹框是否显示
      this.memberDialogVisible = true
      this.getProductInfoListAPI()
    },
    // 获取商品信息
    async getProductInfoListAPI() {
      const obj = {
        productType: '08' // 商品类型(01 个人vip 02 企业svip 03 企业svip 04 积分 05 匹配次数 06导出数据 08 大模型会员)
      }
      const { data } = await getProductInfoListAPI(obj)
      this.priceList = data
      this.priceList.forEach(item => {
        switch (item.productName) {
          case '12个月VIP': item.originalPrice = '599.8'; break
          case '3个月VIP': item.originalPrice = '168.8'; break
          case '1个月VIP': item.originalPrice = '59.8'; break
        }
      })
      this.activePrictItem = this.priceList[0] // 给当前选择的价格套餐赋值
      this.showPaymentQrCode()
    },
    // 支付
    async showPaymentQrCode(ind, name) {
      clearInterval(this.memberTimer)
      const productId = this.activePrictItem.productId
      const { data } = await getDmxVipInfoAPI({ productId: productId })
      this.orderInfo = data
      // this.GET_USER_INFO.vipLastTime =
      this.IsShowQrcode = true
      // 订单创建
      // 订单来源于哪个系统(01 企惠查 02 大模型),默认是01 企惠查
      const res = await orderCreateAPI({ productId: productId, orderType: '02' })
      this.qianHtml = res.data.fromStr
      this.orderNo = res.data.orderNo
      // 轮询查询订单状态
      this.memberTimer = setInterval(() => {
        this.orderQueryAPI(this.orderNo)
      }, 3000)
    },
    // 支付方式
    changepaymentFn(ind) {
      if (ind === 1) return this.$message.info('微信支付暂未开通')
      this.payment = ind
    },
    // 轮询查询 查询订单状态
    async orderQueryAPI(orderNo) {
      // 如果支付成功 退出状态查询
      if (this.paySuccess) return clearInterval(this.memberTimer)
      // 如果支付成功 退出状态查询
      const { data } = await orderQueryAPI({ orderNo: orderNo })
      this.paySuccess = data
      if (this.paySuccess) {
        // 支付成功后
        this.GET_USER_INFO.vipIs = true
        this.SET_USER_INFO(this.GET_USER_INFO)
        this.remainingDaysAPI()// 获取大模型会员剩余天数
      }
      // 支付成功
      // 支付失败
    },
    // 获取大模型会员剩余天数
    async remainingDaysAPI() {
      const { data } = await remainingDaysAPI()
      this.GET_USER_INFO.vipLastTime = data.remainingDays
      this.SET_USER_INFO(this.GET_USER_INFO)
    },
    // 支付成功
    successFn() {
      this.paySuccess = false
      this.$message.success('支付成功')
      this.memberDialogVisible = false
      clearInterval(this.memberTimer)
      this.memberTimer = null
    },
    // 修改价格
    changePriceFn(item, ind) {
      if (this.priceInd === ind) return
      clearInterval(this.memberTimer)
      this.memberTimer = null
      this.activePrictItem = item
      this.priceInd = ind
      this.showPaymentQrCode()
    }
  }
}
</script>

<style scoped lang="less">
.recommend_price {
  font-size: 34px;
  font-weight: 500;
  color: #f71414;
}
.single_test {
  position: absolute;
  top: 20px;
  left: 0px;
  width: 138px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 186px;
  height: 46px;
  border-radius: 0px 23px 23px 0px;
  line-height: 46px;
  text-align: center;
  display: flex;
  align-items: center;

  background: linear-gradient(180deg, #ffa966 0%, #ff7d18 100%);
  cursor: pointer;
  img {
    width: 22px;
    height: 22px;
    margin-right: 2px;
  }
}
/deep/.el-dialog {
  margin-top: 8vh !important;
}
@vip_color: #ff7d18;
.used_coupon_color {
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @vip_color;
    border-color: @vip_color;
  }
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: @vip_color;
  }
}
.used_coupon_btn_color {
  color: @vip_color;
  :hover {
    color: @vip_color !important;
  }
}
/deep/.el-button--text:focus,
.el-button--text:hover {
  color: @vip_color !important;
}

.evaluation_price {
  width: 50%;
  margin: auto;
}
.IntegralDialog {
  /deep/.el-dialog__headerbtn .el-dialog__close {
    color: white;
  }
  /deep/ .el-dialog__header {
    padding: 0;
    border-radius: 8px 8px 0px 0px;
    //background: url(../../assets/images/businessAccount/VIP.png);
    /deep/ .el-dialog__headerbtn {
      /deep/ .el-dialog__close {
        font-size: 16px;
      }
    }
  }
  /deep/ .el-dialog {
    border-radius: 10px;
  }
  /deep/ .dialog_title {
    width: 100%;
    height: 120px;
    background: #3089c1;
    background: linear-gradient(180deg, #f9bd6c 0%, #ffe2c0 100%);

    border-radius: 8px 8px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    .vip {
      position: absolute;
      bottom: 0px;
      right: 75px;
      z-index: 100;
      img {
        width: 115px;
        height: 100px;
      }
    }

    .title {
      height: 24px;
      font-size: 24px;
      font-weight: 500;
      color: #752a00;
      line-height: 22px;
      margin-top: 30px;
      margin-bottom: 24px;

      img {
        width: 25px;
        height: 22px;
        cursor: pointer;
        margin-left: 7px;
        display: inline-block;
        margin-top: 0px;
        vertical-align: top;
      }
    }
    .title_content {
      height: 18px;
      font-size: 18px;
      font-weight: 400;
      color: #752a00;
      line-height: 22px;
      position: relative;
      z-index: 1000;
    }
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    font-size: 20px !important;
    color: #ffffff;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .main {
    height: auto;
    box-sizing: border-box;
    padding-bottom: 32px;
    /deep/ .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 209px;
      padding: 0 84px;
      .price_item {
        cursor: pointer;
        // background-color: pink;
        width: 200px;
        height: 120px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #3f75f0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .money_lose {
          font-size: 34px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 550;
          color: #9ea6b1;
          line-height: 18px;
          margin-top: 8px;
          margin-bottom: 8px;
          text-decoration: line-through;
        }
        .using_vouchers {
          font-size: 34px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 550;
          color: #f71414;
          line-height: 18px;
          margin-top: 0;
          margin-bottom: 8px;
          img {
            width: 33px;
            height: 33px;
          }
          span {
            margin-right: 33px;
          }
        }
        .money {
          font-size: 34px;
          line-height: 34px;
          font-weight: 700;
          color: #333333;
        }
        .integral {
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          margin-bottom: 11px;
        }
        .preferential {
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-top: 8px;
        }
        .preferential_through{
          text-decoration: line-through;
        }
        // 推荐
        .recommend {
          width: 56px;
          height: 28px;
          line-height: 28px;
          opacity: 1;
          text-align: center;
          font-weight: 400;
          position: absolute;
          top: -16px;
          left: -2px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        // 限时
        .limited {
          width: 56px;
          height: 28px;
          line-height: 28px;
          opacity: 1;
          text-align: center;
          font-weight: 400;
          position: absolute;
          top: -16px;
          left: -2px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .price_active {
        width: 220px;
        height: 150px;
        background: #fff8f3;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 2px solid @vip_color;
        transition: 0.1s;
        .integral {
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
        }
        .preferential {
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #004dc6;
          line-height: 14px;
        }
      }
    }
    .price_chatBot{
      padding: 0 27px;
    }
    .useCoupon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      position: relative;
      top: -10px;
      .useCoupon_checked {
        margin-right: 198px;
      }
    }
    .price_btn {
      width: 700px;
      height: auto;
      margin: auto;
      display: flex;
    }

    // 底部文字
    .foot {
      padding: 0 8px;
      margin-top: 22px;
      width: 822px;
      height: 96px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding-bottom: 22px;
    }

    // 二维码
    .qrcode {
      width: 780px;
      height: 290px;
      background: rgba(209, 224, 232, 0.2);
      background: #fffaf7;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      margin: auto;
      .left {
        box-sizing: border-box;
        width: 50%;
        margin-left: 45px;
        padding-top: 32px;
        .account {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          line-height: 14px;
          margin-bottom: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .PaidInAmount {
          margin-bottom: 0;
        }
        .residue {
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          margin-bottom: 12px;
          margin-top: 8px;
        }
        .alipay {
          cursor: pointer;
          width: 196px;
          height: 54px;
          background: #ffffff;
          border: 1px solid #ffe0c9;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          color: #333333;
          line-height: 22px;
          margin-bottom: 8px;
          position: relative;
          img {
            width: 34px;
            height: 34px;
            margin: 0 18px;
          }
        }
        .active_pay {
          background: #eef7fd;
          background: rgba(255, 125, 24, 0.05);

          border: 1px solid @vip_color;
        }
        .agreement {
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #855e48;
          line-height: 22px;
          a{
            color: #855e48;
          }
          a:hover{
            color: #3265f6;
          }
        }
        .choose_pay {
          position: absolute;
          top: -3px;
          right: -1px;
          img {
            width: 28px;
            height: 22px;
            margin: 0;
          }
        }
      }
      .right {
        margin-right: 88px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .code {
          width: 172px;
          height: 172px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
        .scan {
          margin-top: 19px;
          width: 200px;
          height: 52px;
          background: #eef5f9;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          display: flex;
          align-items: center;

          font-size: 14px;
          font-weight: 400;
          color: #515151;
          line-height: 20px;
          letter-spacing: 5px;
          img {
            width: 38px;
            height: 38px;
            margin-left: 12px;
            margin-right: 9px;
          }
        }

        // 支付成功
        .success_pay {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          .success_pay_btn {
            width: 140px;
            height: 50px;
            background: #ff7d18;
            border-radius: 5px 5px 5px 5px;
            opacity: 1;
            text-align: center;
            line-height: 50px;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            margin-top: 24px;
            margin-bottom: 10px;
            box-sizing: border-box;
            cursor: pointer;
          }
          .success_text {
            height: 16px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-top: 12px;
          }
          img {
            width: 86px;
            height: 86px;
          }
        }
      }
    }
  }
}
#zfbCode {
  width: 204px;
  height: 202px;
  transform: scale(0.85);
  margin-top: -15px;
  margin-left: -16px;
}
#zfbCode canvas {
  width: 172px;
  height: 172px;
}
.coupon_item {
  flex-shrink: 0;
  width: 298px;
  height: 100px;
  // background: #fff0ce;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  // margin-right: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid #fde7c1;
  //background-image: url('../../assets/images/businessAccount/isNotuseCoupon.png');
  background-size: 100% 100%;
  margin-bottom: 10px;
  cursor: pointer;
  .coupon-left {
    font-size: 16px;
    width: 95px;
    height: 98px;
    // background: #faf0d4;

    border-radius: 7px 0px 0px 7px;
    opacity: 1;
    text-align: center;
    line-height: 98px;
    .coupon-money {
      font-weight: 700;
      color: #422813;
      font-size: 34px;
    }
  }
  .coupon-right {
    flex: 1;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
    // background-color: #fffaee;
    border-radius: 8px 8px 8px 8px;
    .coupon-name {
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 5px;
      margin-top: 6px;
    }
    .coupon-info {
      /* 允许单词内换行 */
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      /* 开启盒子的弹性布局 */
      display: -webkit-box;
      /* 控制第几行出现省略号 */
      -webkit-line-clamp: 2;
      /* 设置子元素从上向下垂直排列 */
      -webkit-box-orient: vertical;
    }
    .coupon-time {
      margin-top: 8px;
      white-space: nowrap;
    }
  }
}
.coupon_item_active {
  //   background-image: url(../../assets/images/businessAccount/isCoupon_use.png);
}
.useCoupon_popover {
  height: 300px !important;
  overflow-y: scroll !important;
}
.price_line {
  width: 1px;
  height: 72px;
  opacity: 1;
  background: #fde0b2;
}
.price_activity {
  width: 120px !important;
  height: 120px !important;
  border-radius: 8px 8px 8px 8px !important;
  opacity: 1 !important;
  border: 1px solid rgba(247, 57, 20, 0.7) !important;
  padding: 15px;
  .integral {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #000000 !important;
    line-height: 26px !important;
    text-align: center !important;
    margin-top: -40px;
  }
}
.public_beta_period{
  font-size: 15px;
  position: absolute;
  top: 62px;
  right: -2px;
}
.public_beta_period_two{
  font-size: 13px;
  position: absolute;
  top: 51px;
  right: -3px;
  color: #f71414;
  font-weight: 400;
}
</style>
<style scoped>
/deep/.el-tooltip__popper {
  max-width: 10% !important;
}
/deep/.qrcode-img-area {
  /* transform: scale(0.5); */
  width: 172px;
  height: 172px;
  border: 1px solid red;
}
/deep/.qrcode-img-area canvas {
  /* transform: scale(0.5); */
  width: 172px;
  height: 172px;
  border: 1px solid red;
}
</style>
