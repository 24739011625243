import request from '@/utils/request'
/**
 * @returns 问题点赞点踩
 * @param {number} questionAnswerId  问答ID
 * @param {string} talkId 对话ID
 * @param {boolean} likeOrStomp 点赞true/点踩false
 */
export const likeOrStompAPI = (talkId, questionAnswerId, likeOrStomp) =>
  request({
    url: '/kxUser/likeOrStomp',
    method: 'post',
    data: {
      talkId,
      questionAnswerId,
      likeOrStomp
    }
  })

/**
 * 查询会话中的问答
 * @param {String} talkId  会话ID
 * @returns 问答列表
 */
export const getQueryTalkListAPI = (talkId) =>
  request({
    url: '/kxQuestionAnswer/queryTalk',
    method: 'post',
    data: {
      talkId
    }
  })
/**
 *删除会话
 * @param {number} talkId  会话ID
 * @returns
 */
export const deleteQueryTalkAPI = (talkId) =>
  request({
    url: '/kxTalk/delete',
    method: 'post',
    data: {
      talkId
    }
  })

/**
 * 向大模型提问
 * @param {Object} data  对话Id ：talkId ；用户输入的查询内容 ：query ; 用于搜索的知识库名称 :knowledgeBaseName
 * @returns
 */
// export const dmxUserAPI = (data) =>
//   request({
//     url: '/dmxUser/talk',
//     method: 'post',
//     data
//   })
/**
 *修改历史对话名称
 * @param {String} talkId 对话Id
 * @param {String} talkName 对话名称
 * @returns
 */
export const updateTalkNameAPI = (talkId, talkName) =>
  request({
    url: '/kxUser/updateTalkName',
    method: 'post',
    data: {
      talkId,
      talkName
    }
  })
/**
 * 扣减次数（前端直接请求）
 * @param {string} talkId 对话Id
 * @param {string} query 用户输入的查询内容
 * @param {string} knowledgeBaseName 用于搜索的知识库名称
 * @returns
 */
export const dmxUserReduceAPI = (talkId, query, knowledgeBaseName) =>
  request({
    url: '/kxTalk/reduce',
    method: 'post',
    data: {
      talkId,
      query,
      knowledgeBaseName
    }
  })
/**
 * 回调（前端直接请求）
 * @param {string} answer  回答
 * @returns
 */
export const dmxUserReduceCallbackAPI = (answer,stopGenerated) =>
  request({
    url: '/kxTalk/gy/reduce/callback',
    method: 'post',
    data: {
      answer,
      stopGenerated
    }
  })

/**
 * 查询随机6条热门问答
 * @param {String} model
 * @returns
 */
export const hotQAAPI = (model) =>
  request({
    url: '/dmxUser/hotQA',
    params: {
      model
    }
  })
  /**
 * 查询随机热门问答,无需登录
 * @param {String} model
 * @returns
 */
export const hotQA = (model) =>
request({
  url: '/dmxUser/exclude/hotQA',
  params: {
    model
  }
})
/**
 * 查询用户的历史对话对话列表
 * @returns
 */
export const historyTalkListAPI = () =>
  request({
    url: '/kxUser/talkList'
  })
