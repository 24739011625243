import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element-ui
import ElementUI from 'element-ui'
// element-ui css
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/base.css'
//  vant
import Vant from 'vant'
import 'vant/lib/index.css'
// 日期插件

import MemberDialog from './components/VIP/memberDialog.vue' // 会员
import dayjs from 'dayjs'
Vue.prototype.dayjs = dayjs
const MyPlugin = {
  install(Vue) {
    // 注册全局组件
    Vue.component('MemberDialog', MemberDialog) // 积分
  }
}
Vue.use(MyPlugin) // MemberDialog
Vue.use(Vant) // vant

Vue.config.productionTip = false
// element-ui 组件
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
