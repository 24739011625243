import Vue from 'vue'
import Vuex from 'vuex'
import createPersistentState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistentState({
      key: 'xqtech_chat',
      // storage: window.sessionStorage,  //  设置存储到会话存储
      reducer({ userInfo }) {
        return { userInfo }
      }
    })
  ],
  state: {
    userInfo: {}
  },
  getters: {
    // 获取用户信息
    GET_USER_INFO({ userInfo }) {
      return userInfo
    }
  },
  mutations: {
    // 设置用户信息
    SET_USER_INFO(state, info) {
      state.userInfo = info
    },
    // 设置用户剩余问题
    SET_USER_remaining_question(state, remainingQuestion) {
      state.userInfo.remainingQuestion = remainingQuestion
    },
    // 设置用户是否为新用户
    SET_USER_newUserIs(state, newUserIs) {
      state.userInfo.newUserIs = newUserIs
    }
  },
  actions: {},
  modules: {}
})
