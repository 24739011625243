import request from '@/utils/request'
/**
 *大模型用户登录
 * @param {object} data mobile,password,code,loginType:登录方式(01 密码登录 02 验证码登录),
 * registerReceiveIs:number注册时是否领取问答权益
 * @returns 用户信息
 */
export const dmxUserLoginAPI = (data) =>
  request({
    url: '/kxLogin',
    method: 'post',
    data
  })

/**
 *发送验证码
 * @param {String} phone 手机号
 * @param {String} businessType 业务种类（01 用户注册 02 手机修改 03 邮箱修改 04 找回密码 05 密码修改 06反馈回复提示 07 用户登录 08 注册登录）
 * @param {Number} platform 发送短信使用的平台名称 0 企惠查平台 1 雪晴科技
 * @returns
 */
export const sendCodeAPI = (phone, businessType, platform) =>
  request({
    url: '/userLogin/sendCode',
    method: 'post',
    data: {
      phone,
      businessType,
      platform
    }
  })

/**
 * 科协用户退出登录
 * @returns
 */
export const logoutAPI = () =>
  request({
    url: '/kxLogin/logout',
    method: 'post'
  })
/**
 * 大模型老用户邀请信息
 * @returns
 */
// export const invitationInformationAPI = () =>
//   request({
//     url: '/dmxUser/invite',
//     method: 'get'
//   })

/**
 * 提交反馈
 * @returns
 */
// export const submitDmxFeedbackAPI = (data) =>
//   request({
//     headers: {
//       'content-type': 'multipart/form-data'
//     },
//     url: '/dmxFeedback/submitDmxFeedback',
//     method: 'post',
//     data
//   })
/**
 * 查询职位信息
 * @returns
 */
// export const queryPositionAPI = () =>
//   request({
//     url: '/dmxUser/queryPosition',
//     method: 'get'
//   })
/**
 * 找回密码
 * @returns
 */
export const findPasswdAPI = (data) =>
  request({
    url: '/kxUser/findPasswd',
    method: 'post',
    data
  })
/**
 * 删除账号
 * @returns
 */
export const deleteAccountAPI = (data) =>
  request({
    url: '/dmxUser/deleteAccount',
    method: 'post',
    data
  })
/**
 * 修改用户信息
 * @returns
 */
// export const updateUserInfoAPI = (data) =>
//   request({
//     url: '/dmxUser/updateUserInfo',
//     method: 'post',
//     data
//   })
/**
 * 获取用户是否登录
 * @returns
 */
export const isLoginAPI = () =>
  request({
    url: '/kxLogin/isLogin'
    // method: 'post',
  })
/**
 * 用户修改密码
 * @returns
 */
export const passChange = (data) =>
  request({
    url: '/kxUser/passChange',
    method: 'post',
    data
  })
