import request from '@/utils/request'
/**
 *获取商品信息
 * @param {object} data
 */
export const getProductInfoListAPI = (params) =>
  request({
    url: '/productInfo/getProductInfoList',
    method: 'get',
    params: params
  })

/**
 *获取大模型开通或续费的vip到期时间等信息
 * @param {object} data
 */
export const getDmxVipInfoAPI = (params) =>
  request({
    url: '/productInfo/getDmxVipInfo',
    method: 'get',
    params: params
  })

/**
 *创建订单
 * @param {object} data
 */
export const orderCreateAPI = (data) =>
  request({
    url: '/alipay/orderCreate',
    method: 'post',
    data: data
  })

/**
 *查询订单
 * @param {object} data
 */
export const orderQueryAPI = (params) =>
  request({
    url: '/alipay/orderQuery',
    method: 'get',
    params: params
  })
/**
 *获取大模型会员剩余天数
 * @param {object} data
 */
export const remainingDaysAPI = () =>
  request({
    url: '/productInfo/remaining-days',
    method: 'get'
  })
